import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { BaseForm, ClientRole } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { union } from 'lodash-es';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-role-item-permission-form',
  templateUrl: './role-item-permission-form.component.html',
  styleUrls: ['./role-item-permission-form.component.scss'],
})
export class RoleItemPermissionFormComponent extends BaseForm<ClientRole> implements OnInit {
  private _client: {
    name?: string;
    key?: string;
    permissionsList?: { name: string; key: string; types: { id: number; type: string }[] }[];
  } = {};
  totalPermsCount = 0;
  @Input() parentForm: AbstractControl<any>;
  @Input() set client(client: {
    name?: string;
    key?: string;
    permissionsList?: { name: string; key: string; types: { id: number; type: string }[] }[];
  }) {
    this._client = client;
    this.totalPermsCount = 0;
    client.permissionsList.forEach((perm) => {
      if (perm.types.length) this.totalPermsCount += perm.types.length;
    });
    this.formGroup?.controls?.name?.patchValue(client?.key);
  }
  get client() {
    return this._client;
  }
  formControlT = new FormControl(false);
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'ROLE');
  }

  ngOnInit(): void {
    this.formControlT.valueChanges.subscribe((res) => {});
    this.formGroup?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (
        res?.permissionsList?.length &&
        this.totalPermsCount &&
        res?.permissionsList?.length >= this.totalPermsCount
      ) {
        this.formControlT.patchValue(true);
      } else {
        this.formControlT.patchValue(false);
      }
      if (this.parentForm) {
        let pClients = this.parentForm.value as any[];
        const ind = pClients ? pClients?.findIndex((x) => x?.name == res?.name) : -1;
        if (ind != -1) {
          pClients?.splice(ind, 1);
        }
        if (res?.permissionsList?.length > 0) {
          this.parentForm?.patchValue(this.parentForm?.value ? [...this.parentForm?.value, res] : [res]);
        }
      }
    });
  }

  getData() {
    return this.formGroup.getRawValue();
    // return this.viewModeService.viewMode == 'create' ? this.getDataKeyValueFormat() : this.getChangedFormValues().updateItems
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
    if (
      this.formGroup?.value?.permissionsList?.length &&
      this.totalPermsCount &&
      this.formGroup?.value?.permissionsList?.length >= this.totalPermsCount
    ) {
      this.formControlT.patchValue(true);
    } else {
      this.formControlT.patchValue(false);
    }
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null),
      permissionsList: new FormControl(null),
    });
  }
  toggleCheckbox(event, type: string, key = null) {
    switch (type) {
      case 'all':
        if (event.checked) {
          this.formGroup.controls.permissionsList.patchValue(this.getAllPermissions());
        } else {
          this.formGroup.controls.permissionsList.patchValue([]);
        }
        break;
      case 'admin':
        if (event?.checked?.indexOf(key + '-admin') > -1) {
          this.formGroup.controls.permissionsList.patchValue(
            union(this.formGroup.controls.permissionsList.value, [
              key + '-admin',
              key + '-update',
              key + '-create',
              key + '-read',
            ])
          );
        }
        break;
      case 'read':
        if (event?.checked?.indexOf(key + '-read') > -1) {
        } else {
          let patchArray = [];
          event?.checked?.forEach((element: string) => {
            if ([key + '-admin', key + '-update', key + '-create', key + '-read'].indexOf(element) == -1) {
              patchArray.push(element);
            }
          });
          this.formGroup.controls.permissionsList.patchValue(patchArray);
        }
        break;
      case 'update':
        if (event?.checked?.indexOf(key + '-update') > -1) {
          this.formGroup.controls.permissionsList.patchValue(
            union(this.formGroup.controls.permissionsList.value, [key + '-update', key + '-read'])
          );
        } else {
          let indexOfAdmin = event?.checked?.indexOf(key + '-admin');
          if (indexOfAdmin > -1) {
            (event?.checked as string[])?.splice(indexOfAdmin, 1);
            this.formGroup.controls.permissionsList.patchValue(event?.checked);
          }
        }
      case 'create':
        if (event?.checked?.indexOf(key + '-create') > -1) {
          this.formGroup.controls.permissionsList.patchValue(
            union(this.formGroup.controls.permissionsList.value, [key + '-create', key + '-read'])
          );
        } else {
          let indexOfAdmin = event?.checked?.indexOf(key + '-admin');
          if (indexOfAdmin > -1) {
            (event?.checked as string[])?.splice(indexOfAdmin, 1);
            this.formGroup.controls.permissionsList.patchValue(event?.checked);
          }
        }
        break;
    }
  }
  getAllPermissions() {
    let ret: string[] = [];
    this.client.permissionsList.forEach((perm) => {
      perm.types.forEach((permType) => {
        ret.push(perm.key + (permType.type ? '-' + permType.type : ''));
      });
    });
    return ret;
  }
}
