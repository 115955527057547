import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, Client, ClientRole, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { sortBy } from 'lodash-es';

@Component({
  selector: 'app-role-item-form',
  templateUrl: './role-item-form.component.html',
  styleUrls: ['./role-item-form.component.scss'],
})
export class RoleItemFormComponent extends BaseForm<ClientRole> implements OnInit {
  private _clients: {
    name: string;
    key: string;
    permissionsList: { name: string; key: string; types: { id: number; type: string }[] }[];
  }[] = [];
  @Input() set clients(clients: Client[]) {
    const cleanClients: Client[] = [];
    clients.forEach((element) => {
      if (element?.permissionsList?.length > 0) {
        cleanClients.push(element);
      }
    });
    this._clients = cleanClients.map((client) => {
      let perms: { [key: string]: { name: string; key: string; types: { id: number; type: string }[] } } = {};
      client.permissionsList.forEach((perm) => {
        let permSplit = perm.split('-');
        let permType = permSplit.length > 0 ? permSplit[permSplit.length - 1] : 'notype';
        let lastWordIndex = perm.lastIndexOf('-');
        let keyName = perm.substring(0, lastWordIndex != -1 ? lastWordIndex : perm.length);
        let permName = keyName.replace(/-/g, ' ');
        const permTypes = ['read', 'create', 'update', 'admin'];
        if (permTypes.indexOf(permType) > -1) {
          if (!perms[permName]) perms[permName] = { name: permName, key: keyName, types: [] };
          switch (permType) {
            case 'read':
              perms[permName].types.push({ id: 1, type: permType });
              break;
            case 'create':
              perms[permName].types.push({ id: 2, type: permType });
              break;
            case 'update':
              perms[permName].types.push({ id: 3, type: permType });
              break;
            case 'admin':
              perms[permName].types.push({ id: 4, type: permType });
              break;
            default:
              perms[permName].types.push({ id: -1, type: permType });
              break;
          }
        } else {
          let fullPermName = perm.replace(/-/g, ' ');
          if (!perms[fullPermName])
            perms[fullPermName] = { name: fullPermName, key: perm, types: [{ id: 1, type: '' }] };
        }
      });
      return {
        name: client?.name?.replace(/-/g, ' '),
        key: client?.name,
        permissionsList: Object.keys(perms).map((x) => {
          return {
            ...perms[x],
            types: sortBy(perms[x].types, function (o) {
              return o.id;
            }),
          };
        }),
      };
    });
  }
  get clientList() {
    return this._clients;
  }
  formControlT = new FormControl(false);
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.Role);
  }

  ngOnInit(): void {
    this.formControlT.valueChanges.subscribe((res) => {});
  }

  getData() {
    return this.formGroup.getRawValue();
    // return this.viewModeService.viewMode == 'create' ? this.getDataKeyValueFormat() : this.getChangedFormValues().updateItems
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.pattern(/^[^/]*$/)]),
      description: new FormControl(null),
      composite: new FormControl(true),
      clients: new FormControl(null),
      clientRole: new FormControl(true),
    });
  }
  logEvent(event) {
    let ind = this.formGroup?.controls?.clients?.value?.findIndex((x) => x.name == event.name);
  }
  getClientByName(clientName) {
    const nameToSearch = clientName?.split(' ')?.join('-');
    return this.data?.clients?.find((x) => x.name == nameToSearch);
  }
}
