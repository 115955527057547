<div class="w-full">
  <div class="flex flex-row justify-content-between w-full">
    <p class="bold">
      {{ 'general.messages.section' | translate }}
    </p>
    <app-check-box
      [labelWidthFit]="true"
      (onCheckboxChange)="toggleCheckbox($event, 'all')"
      [label]="'general.actions.selectAll' | translate: 'Select All'"
      [name]="client.name"
      [control]="formControlT"
    >
    </app-check-box>
  </div>
  <p-divider class="w-full"></p-divider>
</div>
@for (perm of client.permissionsList; track perm) {
  <div class="">
    <div class="flex flex-row w-full justify-content-between">
      <p class="bold">
        {{ perm.name | titlecase }}
      </p>
      <div class="flex flex-row gap-2">
        @for (permType of perm.types; track permType) {
          <span class="flex flex-row gap-1">
            <app-check-box
              [labelWidthFit]="true"
              [inputId]="perm.key + '-' + permType.type"
              [binary]="false"
              [label]="'general.actions.' + permType.type | titlecase"
              [value]="perm.key + (permType.type ? '-' + permType.type : '')"
              [name]="'checkbox'"
              [control]="formGroup.controls.permissionsList"
              (onCheckboxChange)="toggleCheckbox($event, permType.type, perm.key)"
            ></app-check-box>
          </span>
        }
      </div>
    </div>
    <p-divider class="w-full"></p-divider>
  </div>
}
