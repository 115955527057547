<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-12">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Role Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'textarea'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
    <p-divider class="w-full"></p-divider>
    <h5 class="mt-0 w-full">{{ 'general.messages.Clients' | translate }}</h5>

    <p-accordion class="w-full" [multiple]="true">
      @for (client of clientList; track client) {
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="flex flex-row justify-content-between">
              <h4 class="mb-0">
                {{ client.name | titlecase }}
              </h4>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <app-role-item-permission-form
              [client]="client"
              (formChanges)="logEvent($event)"
              [formData]="getClientByName(client.name)"
              [parentForm]="formGroup.controls.clients"
            ></app-role-item-permission-form>
          </ng-template>
        </p-accordionTab>
      }
    </p-accordion>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
